import React, { useState } from "react";
import "./VocubPlay.css";
import Flashcard from "./flashcard/flashcard";
import { db } from "./firebase-config";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { useEffect } from "react";
import MemoryLevel from "../Helpful-components/Classes/Enums/MemoryLevel";

function VocubPlay() {
  const [word, setWord] = useState("");
  const [localVocubs, setLocalVocubs] = useState([]);
  const [vocubs, setVocubs] = useState([]);
  const [memScore, setMemScore] = useState(MemoryLevel.parina);

  useEffect(() => {
    if (localStorage.getItem("vocubs") != null) {
      console.log("vocubs Exists on localstorage");
      setLocalVocubs(JSON.parse(localStorage.getItem("vocubs")));

      return;
    }
  }, []);

  useEffect(() => {
    // console.log(data.docs);
    let copy = [...localVocubs];
    setVocubs(copy.filter((vocub) => vocub.memoryScore === +memScore));
  }, [memScore, localVocubs]);

  useEffect(() => {
    let rndNumber = getRndInteger(0, vocubs.length);
    setWord(vocubs[rndNumber]);
  }, [vocubs]);

  const nextWord = () => {
    if (vocubs.length === 0) return;
    else {
      setWord(vocubs[getRndInteger(0, vocubs.length)]);
    }
  };

  return (
    <div>
      <div className="memscore-input-div">
        <label htmlFor="type">Memory Level : </label>
        <select
          className="type"
          name="type"
          id="type"
          onChange={(e) => {
            setMemScore(e.target.value);
          }}
          value={memScore}
        >
          <option value={MemoryLevel.parina}>parina</option>
          <option value={MemoryLevel.motamuti_pari}>motamuti pari</option>
          <option value={MemoryLevel.memorized}>memorized</option>
        </select>
      </div>
      {word && (
        <Flashcard
          word={word ? word : ""}
          options={
            localVocubs.length !== 0 ? getRandomMeanings(localVocubs, word) : ""
          }
          nextWord={nextWord}
        />
      )}
    </div>
  );
}

export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getRandomMeanings(vocubs, word) {
  let randomMeanings = [];

  while (randomMeanings.length < 3) {
    let randomWord = vocubs[getRndInteger(0, vocubs.length)];
    if (
      randomWord.id !== word.id &&
      !randomMeanings.includes(randomWord.meaning)
    )
      randomMeanings.push(randomWord.meaning);
  }

  randomMeanings.push(word.meaning);
  shuffleWords(randomMeanings);
  return randomMeanings;
}
export function shuffleWords(words) {
  for (let i = words.length - 1; i > 0; i--) {
    const newIndex = Math.floor(Math.random() * (i + 1));
    const oldValue = words[newIndex];
    words[newIndex] = words[i];
    words[i] = oldValue;
  }
}
export default VocubPlay;
