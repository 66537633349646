import React from 'react'
import './OutputTable.css'

export default function OutputTable({ multiplicands, multipliers }) {
    return (
        <table className='output-table'>
            <tbody>
                {multipliers.map((row, index) =>
                    <tr key={index}>
                        {multiplicands.map((col, j) =>
                            <td key={j}>{col}*{row}</td>
                        )}
                    </tr>
                )}
            </tbody>

        </table>
    )
}
