
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);




export function PieChart({ wordsDistribution }) {
    const [data, setData] = useState({
            labels: ['Memorized', 'Not Memorized', 'Need Revision', 'UnAllocated',],
            datasets: [
                {
                    label: '# of Vocubs',
                    data: [1, 2, 3, 5],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        });

    useEffect(() => {
        var distribution = {
            p: (wordsDistribution.parina / wordsDistribution.total) * 100,
            m: (wordsDistribution.motamutiPari / wordsDistribution.total) * 100,
            me: (wordsDistribution.memorized / wordsDistribution.total) * 100,
            n: (wordsDistribution.others / wordsDistribution.total) * 100,
        };

        setData({
            labels: [`Memorized`,
             `Not Memorized`, 
             `Need Revision`, 
             `UnAllocated`,],
            datasets: [
                {
                    label: '# of Vocubs',
                    data: [distribution.me, distribution.p, distribution.m, distribution.n],
                    backgroundColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        })

    }, [wordsDistribution])


    return <Pie data={data} style={{backgroundColor:"white",cursor:"pointer"}}/>;
}
