import React from "react";
import TopicCreate from "./TopicCreate";
import TopicList from "./TopicList";

export default function Topic() {
  return (
    <div>
      <TopicCreate />
      <TopicList />
    </div>
  );
}
