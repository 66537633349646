import React, { useEffect, useState } from "react";
import "./css/VocubTest.css";
import LockIcon from "@mui/icons-material/Lock";
export default function VocubTestComponent({
  question,
  options,
  correctAnswer,
  onAnswer,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [answered, setAnswered] = useState(false);

  const handleSelect = (option) => {
    if (!answered) {
      onAnswer(option);
      setSelectedOption(option);
      setAnswered(true);
    }

    //filterout the question answered
    // removeAnswered(question);
  };
  return (
    <div className="testQuestionCard container mx-auto w-full max-w-2xl bg-slate-600 p-2">
      <p className="question text-2xl">{question}</p>
      <ul className="optionsList">
        {options.map((option, index) => (
          <li key={index}>
            <button
              className={
                answered
                  ? "w-full py-1 px-1 bg-grey-300 border-dotted border-2 border-sky-500  text-left"
                  : "w-full py-1 px-1 bg-blue-300 border-dotted border-2 border-sky-500 text-left hover:bg-blue-400"
              }
              onPointerUp={() => handleSelect(option)}
              disabled={answered}
            >
              <input
                className="appearance-none"
                type="radio"
                name={`question_${question}`}
                value={option}
                checked={selectedOption === option}
                onChange={() => handleSelect(option)}
              />
              {selectedOption == option ? <LockIcon /> : ""}
              {option}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
