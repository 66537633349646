import React from "react";
import "./css/VocubResult.css";

export default function VocubResultComponent({
  question,
  options,
  correctAnswer,
  answeredOption,
}) {
  const isCorrect = answeredOption === correctAnswer;

  return (
    <div className="testQuestionCard">
      <p className="question">{question}</p>
      <ul className="optionsList">
        {options.map((option, index) => (
          <li
            key={index}
            className={
              answeredOption === option && !isCorrect
                ? "wrongOption"
                : answeredOption === option && isCorrect
                ? "correctOption"
                : correctAnswer === option
                ? "correctOption"
                : ""
            }
          >
            <label>
              <input
                type="radio"
                name={`question_${question}`}
                value={option}
                checked={answeredOption === option}
                disabled={true}
              />
              <span className="optionText">{option}</span>
              {answeredOption === option && (
                <span className="answerIcon">
                  {isCorrect ? (
                    <span className="correct">&#10004;</span>
                  ) : (
                    <span className="incorrect">&#10008;</span>
                  )}
                </span>
              )}
              {!isCorrect && correctAnswer === option && (
                <span className="correctAnswer">&#10004;</span>
              )}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}
