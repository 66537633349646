import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./VocubQuiz.css";
import SearchBar from "../Helpful-components/SearchBar";
import VocubList from "../Helpful-components/VocubList";
import { DateFormatter } from "./Vocub";
import MemoryLevel from "../Helpful-components/Classes/Enums/MemoryLevel";

function VocubQuiz() {
  const [date, setDate] = useState(DateFormatter(new Date()));
  const [memScore, setMemScore] = useState(MemoryLevel.All);
  const [localVocubs, setLocalVocubs] = useState([]);

  const [vocubsArr, setVocubsArr] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("vocubs") != null) {
      console.log("vocubs Exists on localstorage");
      setLocalVocubs(JSON.parse(localStorage.getItem("vocubs")));

      return;
    }
  }, []);

  useEffect(() => {
    let copy = [...localVocubs];
    if (date === "") {
      if (memScore === 0) {
        setVocubsArr(copy);
      } else {
        setVocubsArr(copy.filter((vocubs) => vocubs.memoryScore === +memScore));
      }
    } else {
      if (memScore === 0) {
        setVocubsArr(copy.filter((vocubs) => vocubs.date === date));
      } else {
        setVocubsArr(
          copy.filter(
            (vocubs) => vocubs.memoryScore === +memScore && vocubs.date === date
          )
        );
      }
    }
  }, [date, memScore, localVocubs]);

  // const handleHide = () => {};

  return (
    <div>
      <SearchBar
        date={date}
        setDate={setDate}
        memScore={memScore}
        setSearchMemScore={setMemScore}
      />
      <VocubList
        vocubsArr={vocubsArr}
        ShowMeaning={false}
        ShowSentence={false}
        ShowType={true}
        ShowDate={true}
        ShowMemoryScore={true}
      />
    </div>
  );
}

export default VocubQuiz;
