import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase-config";
import React from "react";
import { useState } from "react";
import "./Create.css";
import { useEffect } from "react";
import { DateFormatter } from "./Vocub";
import MemoryLevel from "../Helpful-components/Classes/Enums/MemoryLevel";

function CreateVocub() {
  const [word, setWord] = useState("");
  const [meaning, setMeaning] = useState("");
  const [vocubs, setVocubs] = useState([]);
  const [memLevel, setMemLevel] = useState(MemoryLevel.parina);
  const [type, setType] = useState("verb");
  const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [feedbackMessage, setFeedBackMessage] = useState("");

  const [sentence, setSentence] = useState("");
  const [date, setDate] = useState(DateFormatter(new Date()));
  const vocubsCollectionRef = collection(db, "dictionary");

  useEffect(() => {
    const getVocubs = async () => {
      const data = await getDocs(vocubsCollectionRef);
      setVocubs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      console.log(data);
    };

    getVocubs();
  }, []);

  const createVocub = async () => {
    if (
      vocubs.some(
        (vocub) => vocub.word.toLowerCase().trim() === word.toLowerCase().trim()
      )
    ) {
      //show duplicate message
      setShowDuplicateMessage(true);
      setShowSuccessMessage(false);
      setFeedBackMessage("This word already exists!");
    } else {
      //
      await addDoc(vocubsCollectionRef, {
        word: word.toLowerCase().trim(),
        meaning: meaning,
        sentence: sentence,
        date: date,
        memoryScore: memLevel,
        type: type,
      });
      setShowSuccessMessage(true);
      setShowDuplicateMessage(false);
      setFeedBackMessage("Vocub Added Successfully!");
    }
  };

  const duplicationCheck = (stringInp) => {
    let duplicate = vocubs.filter((vocub) =>
      vocub.word.toLowerCase().includes(stringInp.toLowerCase().trim())
    );
    if (duplicate) {
      console.log(duplicate.length);
      setShowDuplicateMessage(true);
      setFeedBackMessage("match found : " + duplicate.length);
    }
  };

  const resetValues = () => {
    setWord("");
    setMeaning("");
    setSentence("");
    setType("");
  };

  return (
    <div className="create-section">
      {(showDuplicateMessage || showSuccessMessage) && (
        <div
          className={`message ${
            showDuplicateMessage ? "red-message" : "green-message"
          }`}
        >
          {feedbackMessage}
        </div>
      )}

      <div className="title">New Vocubulary</div>

      <div className="form-container">
        <form
          id="form"
          className="form-here"
          onSubmit={(e) => {
            e.preventDefault();
            createVocub();
            resetValues();
          }}
        >
          <div className="theory">
            <label htmlFor="Word">Word</label>
            <input
              className="w3-input"
              type="text"
              name=""
              id="Word"
              value={word}
              onChange={(e) => {
                setWord(e.target.value);
                duplicationCheck(e.target.value);
              }}
              required
            />
            <label htmlFor="Meaning">Meaning</label>
            <input
              className="w3-input"
              type="text"
              name=""
              id="Meaning"
              value={meaning}
              onChange={(e) => setMeaning(e.target.value)}
              required
            />
            <label htmlFor="Sentence">Sentence</label>
            <textarea
              name=""
              className="sentence-area"
              id="Sentence"
              value={sentence}
              onChange={(e) => setSentence(e.target.value)}
            ></textarea>

            <label htmlFor="type">Type</label>
            <select
              className="type"
              name="type"
              id="type"
              onChange={(e) => {
                setType(e.target.value);
              }}
              value={type}
            >
              <option value="noun">noun</option>
              <option value="verb">verb</option>
              <option value="adjective">adj</option>
              <option value="adverb">adv</option>
            </select>

            <label htmlFor="type">Memory Level</label>
            <select
              className="type"
              name="type"
              id="type"
              onChange={(e) => {
                setMemLevel(e.target.value);
              }}
              value={memLevel}
            >
              <option value={MemoryLevel.parina}>Not Memorized</option>
              <option value={MemoryLevel.motamuti_pari}>Need Revision</option>
              <option value={MemoryLevel.memorized}>Memorized</option>
            </select>

            <label htmlFor="EntryDate">EntryDate</label>
            <input
              className="w3-input"
              type="date"
              name=""
              id="EntryDate"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>

          <div className="buttons w3-container">
            <input className="w3-btn" type="submit" value="Submit" />
            <input
              className="w3-btn"
              id="reset"
              type="reset"
              value="Reset"
              onClick={resetValues}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateVocub;
