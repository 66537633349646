import React, { useState } from "react";
import VocubTestCreate from "./VocubTestCreate";
import VocubTestComponent from "./VocubTestComponent";
import VocubResultComponent from "./VocubResultComponent";

export default function VocubTest() {
  const [score, setScore] = useState(0);
  const [answeredQuestionsCount, setAnsweredQuestionsCount] = useState(0);
  // const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [quizData, setQuizData] = useState([
    // Add more questions as needed
  ]);
  const [warning, setWarning] = useState("");

  const handleAnswer = (questionId, selectedOption) => {
    const question = quizData.find((q) => q.id === questionId);

    if (question && selectedOption === question.correctAnswer) {
      // Increase the score for correct answers
      setScore((prevScore) => prevScore + 10);
    }

    const updatedQuestions = quizData.map((q) => {
      if (q.id === questionId) {
        return { ...q, answeredOption: selectedOption }; // Add answeredOption to the question
      }
      return q;
    });

    setQuizData(updatedQuestions);

    // setAnsweredQuestions((prevAnswers) => [
    //   ...prevAnswers,
    //   { questionId, selectedOption },
    // ]);

    // Increase the count of answered questions
    setAnsweredQuestionsCount((prevCount) => prevCount + 1);
  };

  const showResult = () => {
    // Create a result view or modal to display the results
    // Iterate through answeredQuestions and match with quizData to display results
    // Render question, correct answer, and highlight user's answer (green for correct, red for wrong)
    // Example: <ResultView questions={quizData} answeredQuestions={answeredQuestions} />
  };
  const resetQuiz = () => {
    setAnsweredQuestionsCount(0);
    setScore(0);
    setTotalScore(0);
    setQuizData([]);
    setWarning("");
  };
  const setNewQuiz = (quizArr) => {
    if (quizData.length == 0) setQuizData(quizArr);
    else {
      setWarning("Plz Reset the prev Quiz first !");
    }
  };
  return (
    <div className="vocubtest">
      <VocubTestCreate
        updateQuiz={setNewQuiz}
        updateTotalScore={setTotalScore}
        resetQuiz={resetQuiz}
      />
      <div>
        <div className="scoreBoard">
          <div className="scoreText">
            Score : {score}/{totalScore}
          </div>
        </div>
        <div className="warning">{warning}</div>
        <div className="testquestions-wrapper">
          {answeredQuestionsCount != quizData.length &&
            quizData.map((questionData, index) => (
              <VocubTestComponent
                onAnswer={(selectedOption) =>
                  handleAnswer(questionData.id, selectedOption)
                }
                key={questionData.id}
                {...questionData}
              />
            ))}
          {answeredQuestionsCount == quizData.length &&
            quizData.map((questionData, index) => (
              <VocubResultComponent key={questionData.id} {...questionData} />
            ))}
        </div>
      </div>
    </div>
  );
}
