import React, { useState, useEffect } from "react";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../../Vocub/firebase-config";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  List,
  ListItem,
  Box,
  IconButton,
  Modal,
  Chip,
} from "@mui/material";
import { DeleteOutline, DownloadForOffline, Edit } from "@mui/icons-material";

const SynonymsCollectionRef = collection(db, "synonyms");

export default function SSetManager() {
  const [localSynonyms, setLocalSynonyms] = useState([]);
  const [synonyms, setSynonyms] = useState([]);
  const [synonymsCount, setSynonymsCount] = useState(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentSet, setCurrentSet] = useState(null);

  const ReloadSynonyms = () => {
    const getTopics = async () => {
      const data = await getDocs(SynonymsCollectionRef);
      const temp_topic = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSynonymsCount(data.docs.length);
      localStorage.removeItem("Synonyms");
      localStorage.setItem("Synonyms", JSON.stringify(temp_topic));
      setLocalSynonyms(temp_topic);
      setSynonyms(temp_topic);
    };

    getTopics();
  };

  useEffect(() => {
    if (localStorage.getItem("Synonyms") != null) {
      setLocalSynonyms(JSON.parse(localStorage.getItem("Synonyms")));
      return;
    }
    const getTopics = async () => {
      const data = await getDocs(SynonymsCollectionRef);
      setSynonyms(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setSynonymsCount(data.docs.length);
      localStorage.setItem(
        "Synonyms",
        JSON.stringify(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
      setLocalSynonyms(JSON.parse(localStorage.getItem("Synonyms")));
    };

    getTopics();
  }, []);

  const handleEditClick = (set) => {
    setCurrentSet(set);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setCurrentSet(null);
  };

  const handleSaveChanges = async () => {
    if (currentSet) {
      const setDocRef = doc(db, "synonyms", currentSet.id);
      await updateDoc(setDocRef, {
        SetName: currentSet.SetName,
        Words: currentSet.Words,
      });
      ReloadSynonyms();
      handleCloseModal();
    }
  };

  const handleSetNameChange = (event) => {
    setCurrentSet({ ...currentSet, SetName: event.target.value });
  };

  const handleWordChange = (index, newWord) => {
    const updatedWords = currentSet.Words.map((word, i) =>
      i === index ? newWord : word
    );
    setCurrentSet({ ...currentSet, Words: updatedWords });
  };

  const handleDeleteWord = (index) => {
    const updatedWords = currentSet.Words.filter((_, i) => i !== index);
    setCurrentSet({ ...currentSet, Words: updatedWords });
  };

  const handleAddWord = () => {
    setCurrentSet({ ...currentSet, Words: [...currentSet.Words, ""] });
  };

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ display: "grid", justifyContent: "center", alignItems: "center" }}
    >
      <Grid item>
        <Card variant="outlined" sx={{ maxWidth: 480 }}>
          <Typography variant="h4">Synonyms Collection</Typography>
          <Typography variant="h5">
            Total Synonyms :{" "}
            <Chip label={synonymsCount} color="primary" variant="outlined" />
          </Typography>
          <Button variant="contained" onClick={() => ReloadSynonyms()}>
            Sync <DownloadForOffline></DownloadForOffline>
          </Button>
        </Card>
      </Grid>
      {localSynonyms.map((synonym) => (
        <Grid item key={synonym.id}>
          <Card sx={{ maxWidth: 480 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>
                {synonym.SetName}{" "}
                <Chip
                  label={synonym.Words.length}
                  color="success"
                  variant="outlined"
                />
              </Typography>{" "}
              <IconButton
                variant="contained"
                size="small"
                onClick={() => handleEditClick(synonym)}
              >
                <Edit fontSize="1rem"></Edit>
              </IconButton>
            </Box>
            <List>
              {synonym.Words.map((word) => (
                <ListItem key={word}>{word}</ListItem>
              ))}
            </List>
          </Card>
        </Grid>
      ))}

      <Modal open={isEditModalOpen} onClose={handleCloseModal}>
        <Card
          sx={{
            maxWidth: 480,
            margin: "auto",
            marginTop: "5rem",
            padding: "1rem",
          }}
        >
          <Typography variant="h6">Edit Synonym Set</Typography>
          {currentSet && (
            <>
              <TextField
                label="Set Name"
                value={currentSet.SetName}
                onChange={handleSetNameChange}
                fullWidth
                sx={{ marginBottom: "1rem" }}
              />
              <List>
                {currentSet.Words.map((word, index) => (
                  <ListItem key={index}>
                    <TextField
                      value={word}
                      onChange={(e) => handleWordChange(index, e.target.value)}
                      fullWidth
                    />
                    <IconButton onClick={() => handleDeleteWord(index)}>
                      <DeleteOutline />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              <Button onClick={handleAddWord}>Add Word</Button>
              <Button
                onClick={handleSaveChanges}
                variant="contained"
                color="primary"
              >
                Save Changes
              </Button>
            </>
          )}
        </Card>
      </Modal>
    </Grid>
  );
}
