import React from "react";
import VocubCard from "./VocubCard/VocubCard";
import "./VocubList.css";

function VocubList({ vocubsArr }) {
  return (
    <div className="vocub-list-container">
      <ul className="vocub-list">
        {vocubsArr.length !== 0 &&
          vocubsArr.map((vocub) => {
            return (
              <VocubCard
                key={vocub.id}
                id={vocub.id}
                word={vocub.word}
                meaning={vocub.meaning}
                sentence={vocub.sentence}
                type={vocub.type}
                date={false && vocub.date}
                memoryScore={vocub.memoryScore}
              />
            );
          })}
      </ul>
    </div>
  );
}

export default VocubList;
