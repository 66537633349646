import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../Helpful-components/Context/AuthContext";
import "./AdminBoard.css";
import Canvas from "./canvas/canvas";
import Chart, { PieChart } from "./chart/PieChart";

function AdminBoard({ vocubCount, handleLoad, wordsDistribution }) {
  const { user, logout } = UserAuth();
  const handleLogout = async () => {
    try {
      await logout();
      Navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <div className="admin-board">
      <div className="admin-account-div">
        <div className="account-email">
          <p className="account-email-text">{user && user.email}</p>
          <div className="logout-button-div">
            <div className="logout-btn" onClick={handleLogout}>
              Logout
            </div>
          </div>
        </div>
      </div>
      <div className="vocub-count">
        <p className="vocub-count-text">Total Vocubs : {vocubCount}</p>
      </div>
      {true && (
        <div className="load-vocub-btn">
          <button onClick={handleLoad}>Reload</button>
        </div>
      )}
      <div className="pie-chart-container">
        <div className="pie-chart">
          <PieChart className="pie-chart-canvas" wordsDistribution={wordsDistribution}/>
        </div>
      </div>
    </div>
  );
}

export default AdminBoard;
