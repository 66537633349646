import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../Vocub/firebase-config";
import "./TopicList.css";
// import { Checkbox } from "@mui/material";

export default function TopicList() {
  const [localTopics, setLocalTopics] = useState([]);
  const [Topics, setTopics] = useState([]);
  const [TopicsCount, setTopicsCount] = useState(0);

  const TopicsCollectionRef = collection(db, "studytopic");

  const ReloadTopics = () => {
    const getTopics = async () => {
      const data = await getDocs(TopicsCollectionRef);
      var temp_topic = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTopicsCount(data.docs.length);
      localStorage.removeItem("Topics");
      localStorage.setItem("Topics", JSON.stringify(temp_topic));
      setLocalTopics(temp_topic);
      setTopics(temp_topic);
    };

    getTopics();
  };

  useEffect(() => {
    if (localStorage.getItem("Topics") != null) {
      console.log("Topics Exists on localstorage");
      setLocalTopics(JSON.parse(localStorage.getItem("Topics")));

      return;
    }
    const getTopics = async () => {
      const data = await getDocs(TopicsCollectionRef);
      setTopics(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setTopicsCount(data.docs.length);
      localStorage.setItem(
        "Topics",
        JSON.stringify(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
      setLocalTopics(JSON.parse(localStorage.getItem("Topics")));
    };

    getTopics();
  }, []);

  /////
  /////
  return (
    <div>
      <div className="topic-total-count">
        <p className="topic-count-text">{TopicsCount}</p>
        <button onClick={ReloadTopics} className="bg-black text-white">
          Reload
        </button>
      </div>
      <div className="topic-list-container px-2">
        <ul className="topic-list">
          {Topics.length !== 0 &&
            Topics.map((Topic) => {
              return (
                <div className="topic-card bg-gray-200 my-1" key={Topic.id}>
                  <div className="revcount-checkbox-container">
                    {Array(3)
                      .fill(<button>✅</button>)
                      .map((element) => element)}
                  </div>
                  <h3 className="text-xl">{Topic.TopicName}</h3>
                  <h5>({Topic.TopicDate})</h5>

                  <p className="text-yellow-800">{Topic.TopicCategory}</p>

                  <div className="action">{}</div>
                </div>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
