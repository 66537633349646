import "./App.css";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from "./components/Home";
import Vocub from "./components/Vocub/Vocub";
import CreateVocub from "./components/Vocub/Create";
import VocubPlay from "./components/Vocub/VocubPlay";
import VocubQuiz from "./components/Vocub/VocubQuiz";
import { AuthContextProvider } from "./components/Helpful-components/Context/AuthContext";
import ProtectedRoute from "./components/Helpful-components/Context/ProtectedRoute";
import Register from "./components/RegisterPage";
import TextToSpeech from "./components/Vocub/TextToSpeech/TextToSpeech";
import MultiplicationTable from "./components/MentalMath/MultipliicationTable";
import TopicCreate from "./components/StudyTopic/TopicCreate";
import Topic from "./components/StudyTopic/Topic";
import VocubTest from "./components/VocubQuiz/VocubTest";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import PieChartIcon from "@mui/icons-material/PieChart";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import QuizIcon from "@mui/icons-material/Quiz";
import CalculateIcon from "@mui/icons-material/Calculate";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AppBar from "@mui/material/AppBar";
import { deepOrange } from "@mui/material/colors";
import { AddBoxOutlined, AddCircleOutline, PlusOne } from "@mui/icons-material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import SSetCreate from "./components/SynonymsSet/Create/SSetCreate";
import SSetManager from "./components/SynonymsSet/List/SSManager";

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <Router>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Vocubuilder App
            </Typography>
            <Button color="inherit">
              <Avatar sx={{ bgcolor: deepOrange[500] }}>M</Avatar>
            </Button>
          </Toolbar>
        </AppBar>

        <Drawer open={drawerOpen} onClose={toggleDrawer}>
          <List>
            <ListItem component={Link} to="/">
              <IconButton color="inherit" size="small">
                <HomeIcon></HomeIcon>
              </IconButton>
            </ListItem>
            {/* <ListItem component={Link} to="/register">
              <IconButton color="inherit" size="small">
                <AppRegistrationIcon></AppRegistrationIcon>
              </IconButton>
            </ListItem> */}
            <ListItem component={Link} to="/vocub">
              <IconButton color="inherit" size="small">
                <PieChartIcon></PieChartIcon>
              </IconButton>
            </ListItem>
            <ListItem component={Link} to="/create">
              <IconButton color="inherit" size="small">
                <AddBoxOutlined></AddBoxOutlined>
              </IconButton>
            </ListItem>
            <ListItem component={Link} to="/play">
              <IconButton color="inherit" size="small">
                <SportsEsportsIcon></SportsEsportsIcon>
              </IconButton>
            </ListItem>
            <ListItem component={Link} to="/study">
              <IconButton color="inherit" size="small">
                <MenuBookIcon></MenuBookIcon>
              </IconButton>
            </ListItem>
            <ListItem component={Link} to="/test">
              <IconButton color="inherit" size="small">
                <QuizIcon></QuizIcon>
              </IconButton>
            </ListItem>
            <ListItem component={Link} to="/math">
              <IconButton color="inherit" size="small">
                <CalculateIcon></CalculateIcon>
              </IconButton>
            </ListItem>
            {/* <ListItem component={Link} to="/topic">
              <IconButton color="inherit" size="small">
                <PostAddIcon></PostAddIcon>
              </IconButton>
            </ListItem> */}
            <ListItem component={Link} to="/synonymsCreate">
              <IconButton color="inherit" size="small">
                <EditNoteOutlinedIcon></EditNoteOutlinedIcon>
              </IconButton>
            </ListItem>
            <ListItem component={Link} to="/synonymsList">
              <IconButton color="inherit" size="small">
                <FormatListNumberedOutlinedIcon></FormatListNumberedOutlinedIcon>
              </IconButton>
            </ListItem>
            <ListItem></ListItem>
            <ListItem></ListItem>
            <ListItem></ListItem>
          </List>
        </Drawer>

        <hr />

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <AuthContextProvider>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route
              exact
              path="/register"
              element={
                <ProtectedRoute>
                  <Register />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/vocub"
              element={
                <ProtectedRoute>
                  <Vocub />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/create"
              element={
                <ProtectedRoute>
                  <CreateVocub />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="/play" element={<VocubPlay />}></Route>
            <Route path="/study" element={<VocubQuiz />}></Route>
            <Route path="/test" element={<VocubTest />}></Route>
            <Route path="/math" element={<MultiplicationTable />}></Route>
            <Route path="/topic" element={<Topic />}></Route>
            <Route path="/synonymsCreate" element={<SSetCreate />}></Route>
            <Route path="/synonymsList" element={<SSetManager />}></Route>
          </Routes>
        </AuthContextProvider>
      </Box>
    </Router>
  );
}

export default App;
