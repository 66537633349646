import React from "react";
import { useState } from "react";
import MemoryLevel from "./Classes/Enums/MemoryLevel";
import "./SearchBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarXmark } from "@fortawesome/free-regular-svg-icons";
import { Button, Input } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function SearchBar({
  date,
  setDate,
  word,
  setWord,
  handleSearch,
  memScore,
  setSearchMemScore,
}) {
  return (
    <div className="search-bar-container">
      <form className="search-form">
        <div className="combined-container">
          <div className="date-search">
            <input
              type="date"
              name="date-input"
              id="date-input"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <FontAwesomeIcon
              className="calender-cross"
              onClick={(e) => setDate("")}
              icon={faCalendarXmark}
            />
          </div>
          <div className="memoryScore-search">
            <select
              className="type"
              name="type"
              id="type"
              value={memScore}
              onChange={(e) => {
                setSearchMemScore(e.target.value);
              }}
            >
              <option value={MemoryLevel.All}>All</option>
              <option value={MemoryLevel.parina}>parina</option>
              <option value={MemoryLevel.motamuti_pari}>motamuti pari</option>
              <option value={MemoryLevel.memorized}>memorized</option>
            </select>
          </div>
        </div>

        <div className="byWord-search">
          <label htmlFor="byWord">Search : </label>
          <Input
            type="text"
            name="byWord"
            id="byWord"
            // value={word}
            onBlur={(e) => setWord(e.target.value)}
          />
          <Button variant="contained" onClick={() => handleSearch()}>
            <SearchIcon></SearchIcon>
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
