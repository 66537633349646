
export const DefaultMultiplicands = [12, 13, 14, 15, 16, 17, 18, 19];
export const DefaultMultipliers = [3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15];

export function GetAllPossibleQuestions(Multiplicands = [12, 13, 14, 15, 16, 17, 18, 19],Multipliers= [3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15]) {
    const TableMap = [];
    for (let i = 0; i < Multiplicands.length; i++) {
        for (let j = 0; j < Multipliers.length; j++) {
            let multiple = {
                Multiplicand:Multiplicands[i],
                Multiplier:Multipliers[j],
                Answer:Multiplicands[i]*Multipliers[j]
            }
            TableMap.push(multiple);
        }
    }
    return TableMap;
}

