import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { UserAuth } from "../Helpful-components/Context/AuthContext";
import { db } from "./firebase-config";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import SearchBar from "../Helpful-components/SearchBar";
import AdminBoard from "../AdminBoard/AdminBoard";
import MemoryLevel from "../Helpful-components/Classes/Enums/MemoryLevel";
import "./Vocub.css";
import {
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Container,
} from "@mui/material";

function Vocub() {
  const { user, logout } = UserAuth();
  const [localVocubs, setLocalVocubs] = useState([]);
  const [vocubs, setVocubs] = useState([]);
  const [wordsDistribution, setWordsDistribution] = useState({});

  const [vocubCount, setVocubCount] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [searchMemScore, setSearchMemScore] = useState(MemoryLevel.All);

  const [searchDate, setSearchDate] = useState("");
  const [vocubToEdit, setVocubToEdit] = useState("");
  const vocubsCollectionRef = collection(db, "dictionary");

  useEffect(() => {
    if (localStorage.getItem("vocubs") != null) {
      console.log("vocubs Exists on localstorage");
      setLocalVocubs(JSON.parse(localStorage.getItem("vocubs")));

      return;
    }
    const getVocubs = async () => {
      const data = await getDocs(vocubsCollectionRef);
      setVocubs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setVocubCount(data.docs.length);
      localStorage.setItem(
        "vocubs",
        JSON.stringify(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
      setLocalVocubs(JSON.parse(localStorage.getItem("vocubs")));
    };

    getVocubs();
  }, []);

  useEffect(() => {
    let copy = [...localVocubs];

    setWordsDistribution({
      total: copy.length,
      parina: copy.filter((vocub) => vocub.memoryScore === 1).length,
      motamutiPari: copy.filter((vocub) => vocub.memoryScore === 2).length,
      memorized: copy.filter((vocub) => vocub.memoryScore === 3).length,
      others: copy.filter(
        (vocub) => vocub.memoryScore > 3 || vocub.memoryScore < 1
      ).length,
    });
  }, [localVocubs]);

  const deleteVocub = async (id) => {
    let vocubRef = doc(db, "dictionary", id);
    await deleteDoc(vocubRef);

    setLocalVocubs((prevVocubs) => {
      return prevVocubs.map((vocub, i) => {
        if (vocub.id === id) prevVocubs.splice(i, 1);
        return vocub;
      });
    });
    setVocubCount((prevCount) => prevCount - 1);
  };

  const setVocubEditMode = async (id) => {
    setShowEdit(true);
    let vocubToEdit = vocubs.find((vocub) => vocub.id === id);
    setVocubToEdit(vocubToEdit);
  };
  const updateVocub = async () => {
    let vocubRef = doc(db, "dictionary", vocubToEdit.id);

    await updateDoc(vocubRef, { ...vocubToEdit });

    setLocalVocubs((prevVocubs) => {
      return prevVocubs.map((vocub, i) => {
        if (vocub.id === vocubToEdit.id) return { vocub, ...vocubToEdit };
        else return vocub;
      });
    });

    console.log("Edit successFul");
    setShowEdit(false);
  };

  //Search Methods
  useEffect(() => {
    let copy = [...localVocubs];
    if (searchDate === "") {
      setVocubs(copy);
    } else {
      setVocubs(copy.filter((vocubs) => vocubs.date === searchDate));
    }
    setVocubCount(localVocubs.length);
  }, [searchDate, localVocubs]);
  // Search word
  // useEffect(() => {
  //   let copy = [...localVocubs];
  //   if (searchWord === "") {
  //     setVocubs(copy);
  //   } else {
  //     setVocubs(
  //       copy.filter((vocubs) =>
  //         vocubs.word.toLowerCase().trim().includes(searchWord.toLowerCase())
  //       )
  //     );
  //   }
  // }, [searchWord, localVocubs]);

  const handleSearch = () => {
    let copy = [...localVocubs];
    if (searchWord === "") {
      setVocubs(copy);
    } else {
      setVocubs(
        copy.filter((vocubs) =>
          vocubs.word.toLowerCase().trim().includes(searchWord.toLowerCase())
        )
      );
    }
  };

  const handleSearchOnMemScore = (memscore) => {
    setSearchMemScore(+memscore);
  };

  useEffect(() => {
    let copy = [...localVocubs];
    if (searchMemScore === 0) {
      setVocubs(copy);
    } else {
      setVocubs(copy.filter((vocubs) => vocubs.memoryScore === searchMemScore));
    }
  }, [searchMemScore, localVocubs]);

  const handleLoad = () => {
    const getVocubs = async () => {
      const data = await getDocs(vocubsCollectionRef);
      var temp_vocub = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setVocubCount(data.docs.length);
      localStorage.removeItem("vocubs");
      localStorage.setItem("vocubs", JSON.stringify(temp_vocub));
      setLocalVocubs(temp_vocub);
      setVocubs(temp_vocub);

      console.log("hello");
    };

    getVocubs();
  };

  const deletBtn = (vocub) =>
    user &&
    user.email === "admin@gmail.com" && (
      <Button
        variant="outlined"
        onClick={() => {
          if (window.confirm("Are you sure?")) {
            deleteVocub(vocub.id);
            console.log("Delete Successfull!");
          }
        }}
      >
        Delete
      </Button>
    );

  //
  return (
    <div>
      <AdminBoard
        vocubCount={vocubCount}
        handleLoad={handleLoad}
        wordsDistribution={wordsDistribution}
      />
      <div className="search-options">
        <SearchBar
          date={searchDate}
          setDate={setSearchDate}
          word={searchWord}
          setWord={setSearchWord}
          handleSearch={handleSearch}
          memScore={searchMemScore}
          setSearchMemScore={handleSearchOnMemScore}
        />
      </div>

      {!showEdit && (
        <div className="vocub-list-container">
          <ul className="vocub-list">
            {vocubs.length !== 0 &&
              vocubs.map((vocub) => {
                return (
                  <Card
                    sx={{ maxWidth: 400, marginBottom: "1rem" }}
                    key={vocub.id}
                  >
                    <CardContent>
                      <Typography variant="h6">
                        {vocub.word.toUpperCase()}
                      </Typography>
                      <Typography color="hotpink">
                        ({vocub.type}) {vocub.meaning}
                      </Typography>

                      <Typography variant="body2" color="black" gutterBottom>
                        {vocub.sentence}
                      </Typography>
                      <p>{false && vocub.memoryScore}</p>
                      <div className="action">
                        {user && user.email === "admin@gmail.com" && (
                          <Button
                            variant="outlined"
                            onClick={() => setVocubEditMode(vocub.id)}
                          >
                            Edit
                          </Button>
                        )}
                        {deletBtn(vocub)}
                      </div>
                    </CardContent>
                  </Card>
                );
              })}
          </ul>
        </div>
      )}
      {showEdit && user && user.email === "admin@gmail.com" && (
        <>
          <div className="title">Edit Vocubulary</div>
          <div className="form-container">
            <form
              id="form"
              className="form-here"
              onSubmit={(e) => {
                e.preventDefault();
                updateVocub();
              }}
            >
              <div className="theory">
                <label htmlFor="Word">{vocubToEdit.word}</label>
                <input
                  type="text"
                  name=""
                  id="word"
                  value={vocubToEdit.word}
                  onChange={(e) =>
                    setVocubToEdit({
                      ...vocubToEdit,
                      word: e.target.value,
                    })
                  }
                  required
                />
                <label htmlFor="Meaning">Meaning</label>
                <input
                  className="w3-input"
                  type="text"
                  name=""
                  id="Meaning"
                  value={vocubToEdit.meaning}
                  onChange={(e) =>
                    setVocubToEdit({
                      ...vocubToEdit,
                      meaning: e.target.value,
                    })
                  }
                  required
                />
                <label htmlFor="Sentence">Sentence</label>
                <textarea
                  className="w3-input"
                  name=""
                  id="Sentence"
                  value={vocubToEdit.sentence}
                  onChange={(e) =>
                    setVocubToEdit({
                      ...vocubToEdit,
                      sentence: e.target.value,
                    })
                  }
                ></textarea>

                <label htmlFor="type">Type</label>
                <select
                  className="type"
                  name="type"
                  id="type"
                  onChange={(e) => {
                    setVocubToEdit({ ...vocubToEdit, type: e.target.value });
                  }}
                  value={vocubToEdit.type}
                >
                  <option value="noun">noun</option>
                  <option value="verb">verb</option>
                  <option value="adjective">adj</option>
                  <option value="adverb">adv</option>
                </select>

                <select
                  className="type"
                  name="type"
                  id="type"
                  onChange={(e) => {
                    setVocubToEdit({
                      ...vocubToEdit,
                      memoryScore: +e.target.value,
                    });
                  }}
                  value={vocubToEdit.memoryScore}
                >
                  <option value={MemoryLevel.parina}>Not Memorized</option>
                  <option value={MemoryLevel.motamuti_pari}>
                    Need Revision
                  </option>
                  <option value={MemoryLevel.memorized}>Memorized</option>
                </select>
              </div>

              <div className="buttons w3-container">
                <input className="w3-btn" type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export default Vocub;

export function DateFormatter(dateObj) {
  let dateString = dateObj.toLocaleString("en-GB", {
    day: "numeric",
    year: "numeric",
    month: "numeric",
  });
  let arr = dateString.split("/");
  let date = arr[0];
  let month = arr[1];
  let year = arr[2];
  let output = year + "-" + month + "-" + date;
  return output;
}
