import React, { useState, useEffect } from "react";
// import VolumeUpIcon from '@mui/icons-material/VolumeUp';

function TextToSpeech({ word }) {
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    if (!window.speechSynthesis) {
      alert("Your browser does not support the Web Speech API");
      return;
    }
  }, []);

  //   function handleTextChange(e) {
  //     setText(e.target.value);
  //   }

  function speak() {
    if (!word) {
      return;
    }
    console.log(word);
    setIsSpeaking(true);

    const speech = new SpeechSynthesisUtterance();
    speech.text = word;
    speech.volume = 1;

    window.speechSynthesis.speak(speech);

    speech.addEventListener("end", () => {
      setIsSpeaking(false);
    });
  }

  return (
    <>
      <button onClick={speak} disabled={isSpeaking}>
        {/* <VolumeUpIcon /> */}
        🔊
      </button>
    </>
  );
}

export default TextToSpeech;
