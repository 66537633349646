import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import React from "react";
import { db } from "../Vocub/firebase-config";

export default function TopicCreate() {
  const [TopicName, setTopicName] = useState("");
  const [TopicDate, setTopicDate] = useState("");
  const [TopicRevCount, setTopicRevCount] = useState("");
  const [TopicCategory, setTopicCategory] = useState("");

  const TopicsCollectionRef = collection(db, "studytopic");

  const CreateTopic = async () => {
    await addDoc(TopicsCollectionRef, {
      TopicName: TopicName,
      TopicDate: TopicDate,
      TopicRevCount: TopicRevCount,
      TopicCategory: TopicCategory,
    });
    resetValues();
  };

  const resetValues = () => {
    setTopicName("");
    setTopicDate("");
    setTopicRevCount("");
    setTopicCategory("");
  };

  function handleTopicNameChange(event) {
    setTopicName(event.target.value);
  }

  function handleTopicDateChange(event) {
    setTopicDate(event.target.value);
  }

  function handleTopicRevCountChange(event) {
    setTopicRevCount(event.target.value);
  }

  function handleTopicCategoryChange(event) {
    setTopicCategory(event.target.value);
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        CreateTopic();
      }}
    >
      <label>
        Topic Name :
        <input type="text" value={TopicName} onChange={handleTopicNameChange} />
      </label>
      <br />
      <label>
        Date :
        <input type="date" value={TopicDate} onChange={handleTopicDateChange} />
      </label>
      <br />
      <label>
        Revision Count :
        <input
          type="number"
          value={TopicRevCount}
          onChange={handleTopicRevCountChange}
        />
      </label>
      <br />
      <label>
        Topic Category :
        <input
          type="text"
          value={TopicCategory}
          onChange={handleTopicCategoryChange}
        />
      </label>

      <div className="buttons w3-container">
        <input className="w3-btn" type="submit" value="Submit" />
        <input
          className="w3-btn"
          id="reset"
          type="reset"
          value="Reset"
          onClick={resetValues}
        />
      </div>
    </form>
  );
}
