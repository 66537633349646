import React from "react";
import { Clock } from "./Helpful-components/Clock";
import RegistrationForm from "./Helpful-components/RegistrationForm/RegistrationForm";

export default function Register() {
  return (
    <div>
      <Clock locale="bn-BD" />
      <RegistrationForm />
    </div>
  );
}
