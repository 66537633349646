import React, { useState, useEffect } from 'react';
import './Countdown.css'

function Countdown({handleTimeUp,count,setCount,pause,maxCount}) {




  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count === 0) {
        handleTimeUp();
        setCount(maxCount);
        
      } else {
        if(pause)return
        setCount((prevCount) => prevCount - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [count,pause]);

  return <div id="numpad-countdown">{count}</div>;
}

export default Countdown;
