import React from "react";
import LoginForm from "./Helpful-components/LoginForm/LoginForm";

export default function Home() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}
