export function GetTodayFormatted() {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let formattedDay = day < 10 ? `0${day}` : day;
  let formattedMonth = month < 10 ? `0${month}` : month;

  let formattedDate = `${formattedMonth}/${formattedDay}/${year}`;
  return formattedDate;
}
