import React, { useState } from "react";
import Numpad from "./Sub_Components/Numpad";
import OutputTable from "./Sub_Components/OutputTable";
import "./MultiplicationTable.css";
import {
  DefaultMultiplicands,
  DefaultMultipliers,
} from "./Sub_Components/TableOfNumbers";

export default function MultiplicationTable() {
  const [multiplicandRange, setMultiplicandRange] = useState(
    [...DefaultMultiplicands].pop()
  );
  const [multiplierRange, setMultiplierRange] = useState(
    [...DefaultMultipliers].pop()
  );
  const [multiplicands, setMultiplicands] = useState(DefaultMultiplicands);
  const [multipliers, setMultipliers] = useState(DefaultMultipliers);
  const [countdown, setCountDown] = useState(5);
  const [totalTime, setTotalTime] = useState(0);
  const [numpadVisible, setNumpadVisible] = useState(false);
  const [objectTable, setobjectTable] = useState([]);
  const [singleMultiplicand, setSingleMultiplicand] = useState(12);
  const [singleMultiplier, setSingleMultiplier] = useState(12);

  function handlemultiplicandRangeChange(event) {
    setMultiplicandRange(event.target.value);
    setMultiplicands(
      [...DefaultMultiplicands].filter((x) => x <= event.target.value)
    );
  }
  function increaseSingleMultiplicandChange(event) {
    event.preventDefault();
    setSingleMultiplicand((prevValue) => prevValue + 1);
    // setMultiplicandRange(event.target.value);
    setMultiplicands(
      [...DefaultMultiplicands].filter((x) => x === singleMultiplicand + 1)
    );
  }
  function decreaseSingleMultiplicandChange(event) {
    event.preventDefault();

    setSingleMultiplicand((prevValue) => prevValue - 1);
    // setMultiplicandRange(event.target.value);
    setMultiplicands(
      [...DefaultMultiplicands].filter((x) => x === singleMultiplicand - 1)
    );
  }
  function decreaseSingleMultiplierChange(event) {
    event.preventDefault();

    setSingleMultiplier((prevValue) => prevValue - 1);
    // setMultiplicandRange(event.target.value);
    setMultipliers(
      [...DefaultMultipliers].filter((x) => x === singleMultiplier - 1)
    );
  }
  function increaseSingleMultiplierChange(event) {
    event.preventDefault();

    setSingleMultiplier((prevValue) => prevValue + 1);
    // setMultiplicandRange(event.target.value);
    setMultipliers(
      [...DefaultMultipliers].filter((x) => x === singleMultiplier + 1)
    );
  }

  function handlemultiplierRangeChange(event) {
    setMultiplierRange(event.target.value);
    setMultipliers(
      [...DefaultMultipliers].filter((x) => x <= event.target.value)
    );
  }

  function handlecountdownChange(event) {
    setCountDown(event.target.value);
  }

  function handletotalTimeChange(event) {
    setTotalTime(event.target.value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setNumpadVisible(true);
    setobjectTable(MakeObjectTable());
  };

  const MakeObjectTable = () => {
    let result = [];
    multiplicands.forEach((multiplicand) => {
      multipliers.forEach((multiplier) => {
        result.push({
          multiplicand: multiplicand,
          multiplier: multiplier,
          multiple: multiplicand * multiplier,
          memorization: 0,
        });
      });
    });
    return result;
  };

  return (
    <>
      {!numpadVisible && (
        <form className="multiplication-table-settings-form">
          <label className="single-multiplicand">
            Single Multiplicand :
            <button onClick={decreaseSingleMultiplicandChange}>-</button>
            <input type="number" value={singleMultiplicand} />
            <button onClick={increaseSingleMultiplicandChange}>+</button>
          </label>
          <label className="single-multiplier">
            Single Multiplier :
            <button onClick={decreaseSingleMultiplierChange}>-</button>
            <input type="number" value={singleMultiplier} />
            <button onClick={increaseSingleMultiplierChange}>+</button>
          </label>
          <label>
            Maximum Multiplicand:{multiplicandRange}
            <input
              type="range"
              min={12}
              max={19}
              value={multiplicandRange}
              onChange={handlemultiplicandRangeChange}
            />
          </label>
          <br />
          <label>
            Maximum multiplierRange:{multiplierRange}
            <input
              type="range"
              min={3}
              max={15}
              value={multiplierRange}
              onChange={handlemultiplierRangeChange}
            />
          </label>
          <br />
          <label>
            countdown:
            <input
              type="number"
              value={countdown}
              onChange={handlecountdownChange}
            />
          </label>
          <br />
          <label>
            totalTime:
            <input
              type="number"
              value={totalTime}
              onChange={handletotalTimeChange}
            />
          </label>
          <input
            className="submit-btn"
            type="submit"
            value="Submit"
            onClick={handleSubmit}
          />
        </form>
      )}
      {!numpadVisible && (
        <div>
          <OutputTable
            multiplicands={multiplicands}
            multipliers={multipliers}
          />
        </div>
      )}
      {numpadVisible && (
        <Numpad
          objectTable={objectTable}
          countdown={countdown}
          totalTime={totalTime}
        />
      )}
    </>
  );
}
