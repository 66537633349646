import React, { useState, useEffect, useRef } from "react";
// import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import CheckIcon from "@mui/icons-material/Check";
// import ClearIcon from "@mui/icons-material/Clear";
import "./Numpad.css";
import Countdown from "./Countdown";
function Numpad({ totalTime, countdown, objectTable }) {
  const [displayValue, setDisplayValue] = useState("");
  const [question, setQuestion] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const [count, setCount] = useState(countdown);
  const [pause, setPause] = useState(false);
  const [history, setHistory] = useState([]);
  const listRef = useRef(null);

  useEffect(() => {
    setQuestion(generateQuestion());
  }, []);

  useEffect(() => {
    listRef.current.scrollTo(0, listRef.current.scrollHeight);
  }, [history]);

  function generateQuestion() {
    let randomIndex = Math.floor(Math.random() * objectTable.length);
    let randomElement = objectTable[randomIndex];
    console.log(randomElement);
    const num1 = randomElement.multiplicand;
    const num2 = randomElement.multiplier;
    return { num1, num2, answer: randomElement.multiple };
  }

  const resetCount = () => {
    setCount(countdown);
  };

  const handleTimeUp = () => {
    setTimeIsUp(true);
    setPause(true);
  };

  const handleClick = (e) => {
    if (e.target.id === "backspace") {
      setDisplayValue((prev) => prev.slice(0, prev.length - 1));
    } else if (displayValue.length < 3) {
      setDisplayValue((preveValue) => preveValue + e.target.innerText);
    }
  };

  const handleSubmit = () => {
    if (parseInt(displayValue) === question.answer) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
    resetCount();
    setDisplayValue("");
    setQuestion(generateQuestion());
    setTimeIsUp(false);
    setPause(false);
    setHistory((prevHistory) => {
      return [
        ...prevHistory,
        {
          ques: `${question.num1}x${question.num2}`,
          ans: question.answer,
          isCorrect: parseInt(displayValue) === question.answer,
        },
      ];
    });
  };

  return (
    <div className="numpad-container">
      <ul className="history-list" ref={listRef}>
        {history.map((row) => (
          <li key={Math.random() * 100}>
            {row.ques}={row.ans} {row.isCorrect ? "😎" : "☹️"}
          </li>
        ))}
      </ul>
      <Countdown
        handleTimeUp={handleTimeUp}
        maxCount={countdown}
        pause={pause}
        count={count}
        setCount={setCount}
      />

      {question && (
        <div id="question">
          {question.num1} x {question.num2} =
        </div>
      )}

      <div id="display" maxLength={3}>
        {displayValue}
      </div>
      <div id="numpad">
        <button onClick={handleClick}>1</button>
        <button onClick={handleClick}>2</button>
        <button onClick={handleClick}>3</button>
        <button onClick={handleClick}>4</button>
        <button onClick={handleClick}>5</button>
        <button onClick={handleClick}>6</button>
        <button onClick={handleClick}>7</button>
        <button onClick={handleClick}>8</button>
        <button onClick={handleClick}>9</button>
        <button onClick={handleClick} id="backspace">
          {/* <KeyboardBackspaceIcon /> */}
          ◀️
        </button>
        <button onClick={handleClick}>0</button>

        <button onClick={handleSubmit} id="submit">
          submit
        </button>
      </div>
      {isCorrect !== null && (
        <div id="result">{isCorrect ? "Correct!" : "Wrong!"}</div>
      )}
      {timeIsUp !== null && (
        <div id="result">{timeIsUp ? "time is up!" : ""}</div>
      )}
    </div>
  );
}

export default Numpad;
