import { UserAuth } from "../Context/AuthContext";
import React from "react";
import { useState } from "react";
import "./RegistrationForm.css";
function RegistrationForm() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");

  const { createUser } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await createUser(email, pass);
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };
  return (
    <div className="signup-form-container">
      <div className="register-title">
        <p className="register-title-text">Register New User</p>
      </div>
      <div className="error-div">
        <p className="error-message">{error}</p>
      </div>
      <form onSubmit={handleSubmit} className="signup-form">
        <label htmlFor="email-input">Email : </label>
        <input
          type="text"
          className="email-input"
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="passinput">Pass : </label>
        <input
          type="password"
          name="passinput"
          id="passinput"
          onChange={(e) => setPass(e.target.value)}
        />
        <input className="submit-btn" type="submit" value="signup" />
      </form>
    </div>
  );
}

export default RegistrationForm;
