import { db } from "../../Vocub/firebase-config";
import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import DeleteIcon from "@mui/icons-material/Delete";

export default function SSetCreate() {
  const [setName, setSetName] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [words, setWords] = useState([]);

  //FIREBASE OPERATIONS

  const SynonymsCollectionRef = collection(db, "synonyms");

  const CreateSet = async () => {
    await addDoc(SynonymsCollectionRef, {
      SetName: setName,
      Words: words,
    }).then((d) => {
      //   console.log(d);
      resetValues();
    });
  };

  const resetValues = () => {
    setSetName("");
    setWords([]);
  };

  const handleSetNameChange = (event) => {
    setSetName(event.target.value);
  };

  const handleSearchWordChange = (event) => {
    setSearchWord(event.target.value);
  };

  const addWord = () => {
    if (searchWord.trim()) {
      setWords((prevWords) => [...prevWords, searchWord.trim()]);
      setSearchWord("");
    }
  };

  const deleteWord = (index) => {
    setWords((prevWords) => prevWords.filter((_, i) => i !== index));
  };

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Card variant="outlined" sx={{ maxWidth: 360 }}>
          <Grid container direction="column" spacing={2} padding={2}>
            <Grid item>
              <TextField
                variant="standard"
                label="Synonyms Set Name"
                value={setName}
                onChange={handleSetNameChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => {}}>
                Set Name
              </Button>
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                label="Search Word Name"
                value={searchWord}
                onChange={handleSearchWordChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={addWord}>
                Add Word
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="h6">{setName}</Typography>
              <List>
                {words.map((word, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteWord(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={word} />
                    <Divider />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Button variant="contained" color="primary" onClick={CreateSet}>
              Submit
            </Button>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
