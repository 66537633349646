import { doc, updateDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { db } from "../../Vocub/firebase-config";
import TextToSpeech from "../../Vocub/TextToSpeech/TextToSpeech";
import MemoryLevel from "../Classes/Enums/MemoryLevel";

import "./VocubCard.css";

function VocubCard({ id, word, meaning, type, sentence, memoryScore, date }) {
  const [hideWord, setHideWord] = useState(true);
  const [hideSentence, setHideSentence] = useState(true);
  const [memScore, setMemScore] = useState(memoryScore);

  const updateMemoryScore = async (id) => {
    const userDoc = doc(db, "dictionary", id);
    console.log(userDoc);
    const newFields = {
      memoryScore: +memScore,
    };
    await updateDoc(userDoc, newFields);
    // console.log(id);
  };
  useEffect(() => {
    updateMemoryScore(id);
  }, [memScore]);

  return (
    <div className="vocub-card">
      <h3>
        {word.toUpperCase()} <TextToSpeech word={word} />
      </h3>
      {<h5 className={hideWord ? "hide-word" : ""}>{meaning}</h5>}
      <h6>{type}</h6>
      <p className={hideSentence ? "hide-sentence" : ""}>{sentence}</p>
      <p>{date}</p>
      <p>{memScore}</p>
      <div className="action">
        <button
          className="show-word"
          onClick={() => (hideWord ? setHideWord(false) : setHideWord(true))}
        >
          Show/Hide Word
        </button>
        <button
          className="show-sentence"
          onClick={() =>
            hideSentence ? setHideSentence(false) : setHideSentence(true)
          }
        >
          Show/Hide Sentence
        </button>
      </div>
      <div className="set-memory-score">
        <label htmlFor="type">Memory Level : </label>
        <select
          className="type"
          name="type"
          id="type"
          onChange={(e) => {
            setMemScore(e.target.value);
          }}
          value={memScore}
        >
          <option value={MemoryLevel.parina}>Not Memorized</option>
          <option value={MemoryLevel.motamuti_pari}>Need Revision</option>
          <option value={MemoryLevel.memorized}>Memorized</option>
        </select>
      </div>
    </div>
  );
}

export default VocubCard;
