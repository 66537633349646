import { addDoc, collection, getDocs, query, where } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import MemoryLevel from "../Helpful-components/Classes/Enums/MemoryLevel";
import { db } from "../Vocub/firebase-config";
import { getRandomMeanings, shuffleWords } from "../Vocub/VocubPlay";
import uuid from "react-uuid";
import { GetTodayFormatted } from "../Helpful-components/HelperFunctions/Helpers";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ButtonGroup, Button, TextField, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

export default function VocubTestCreate({
  updateQuiz,
  updateTotalScore,
  resetQuiz,
}) {
  const [testId, setTestId] = useState("");
  const [testDate, setTestDate] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState(10);
  const [randomVocubs, getRandomVocubs] = useState([]);
  const [localVocubs, setLocalVocubs] = useState([]);
  const [memLevel, setMemLevel] = useState(MemoryLevel.parina);

  const vocubsCollectionRef = collection(db, "dictionary");

  useEffect(() => {
    if (localStorage.getItem("vocubs") != null) {
      console.log("vocubs Exists on localstorage");
      setLocalVocubs(JSON.parse(localStorage.getItem("vocubs")));
      setTestId(uuid().replace(/-/g, ""));
      setTestDate(GetTodayFormatted());
      return;
    }
  }, []);

  const createQuiz = () => {
    if (localVocubs.length != 0) {
      let randomVocubs = localVocubs.filter((x) => x.memoryScore == memLevel);
      shuffleWords(randomVocubs);
      randomVocubs = randomVocubs.slice(0, numberOfQuestions);
      console.log(randomVocubs);
      updateTotalScore(randomVocubs.length * 10);
      //
      let questions = [];

      randomVocubs.forEach((vocub) => {
        let questionObj = {
          id: uuid(),
          question: "",
          options: [],
          correctAnswer: "",
        };
        questionObj.question = vocub.word;
        questionObj.correctAnswer = vocub.meaning;
        questionObj.options = getRandomMeanings(randomVocubs, vocub);
        questions.push(questionObj);
      });

      updateQuiz(questions);
    } else {
      console.log("no vocubs found!");
    }
  };

  return (
    <Container className="py-2 bg-white-100">
      <form
        id="quizForm"
        className="quizForm"
        onSubmit={(e) => {
          e.preventDefault();
          createQuiz();
        }}
      >
        <Stack className="container mx-auto bg-grey-900 max-w-2xl">
          <div className="testId">
            <Button variant="contained" size="small">
              Test Id : {testId}
            </Button>
          </div>
          <div className="testDate">
            <Button variant="contained" size="small">
              Test Date : {testDate}
            </Button>
          </div>
          <ButtonGroup
            color="secondary"
            size="small"
            aria-label="medium secondary button group"
          >
            <Button variant="contained" color="secondary" size="small">
              Number Of Questions :
            </Button>
            <Select
              size="small"
              color="secondary"
              name="numberOfQuestions"
              id="numberOfQuestions"
              value={numberOfQuestions}
              onChange={(e) => {
                setNumberOfQuestions(e.target.value);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </ButtonGroup>

          <ButtonGroup
            color="secondary"
            size="small"
            aria-label="medium secondary button group"
          >
            <Button variant="contained" color="secondary" size="small">
              Memory Level :
            </Button>
            <Select
              size="small"
              name="type"
              id="type"
              onChange={(e) => {
                setMemLevel(e.target.value);
              }}
              value={memLevel}
            >
              <MenuItem value={MemoryLevel.parina}>Not Memorized</MenuItem>
              <MenuItem value={MemoryLevel.motamuti_pari}>
                Need Revision
              </MenuItem>
              <MenuItem value={MemoryLevel.memorized}>Memorized</MenuItem>
            </Select>
          </ButtonGroup>

          <ButtonGroup
            variant="contained"
            aria-label="contained primary button group"
          >
            <Button type="submit" variant="contained">
              Submit
            </Button>
            <Button
              type="reset"
              variant="contained"
              id="reset"
              onClick={resetQuiz}
            >
              Reset
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
    </Container>
  );
}
