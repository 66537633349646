import React from "react";
import { useState } from "react";
import "./flashcard.css";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

function Flashcard({ word, options, nextWord }) {
  const [message, setMessage] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  // console.log(options);
  return (
    <Card variant="outlined" sx={{ maxWidth: 345, margin: "0px auto" }}>
      <div className="message">{message} </div>
      <div className="word">{word.word.toUpperCase()}</div>
      {!showDetails &&
        options.map((option) => (
          <div
            key={Math.random() * 100}
            onClick={() => {
              // console.log(option);
              if (option === word.meaning) {
                setMessage("Correct Answer");
                setShowDetails(true);
                setShowNextButton(true);
              } else {
                setShowDetails(true);
                setShowNextButton(true);
                setMessage("Wrong Answer!");
              }
            }}
            className="option"
          >
            {option}
          </div>
        ))}
      {showDetails && (
        <div className="details">
          <div className="meaning">{word.meaning}</div>
          <div className="sentence">{word.sentence}</div>
        </div>
      )}
      {showNextButton && (
        <div>
          <Button
            onClick={() => {
              nextWord();
              setShowDetails(false);
              setShowNextButton(false);
            }}
            variant="contained"
            color="success"
          >
            Next
          </Button>
        </div>
      )}
    </Card>
  );
}

export default Flashcard;
