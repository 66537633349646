// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCUQnjvBKyIkL1V-itoXF8nZxEFmAEma3s",
  authDomain: "mydictionary-df06c.firebaseapp.com",
  projectId: "mydictionary-df06c",
  storageBucket: "mydictionary-df06c.appspot.com",
  messagingSenderId: "417897811947",
  appId: "1:417897811947:web:ce6466a89225e4780ad3ae",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
