import React from "react";
import Button from "./Button";
import "./Clock.css";

export class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date(), locale: "bn-BD" };
  }
  componentDidMount() {
    this.clocktimer = setInterval(() => {
      this.tick();
    }, 1000);
  }
  handleClick = (locale) => {
    this.setState({
      locale,
    });
  };

  tick = () => {
    this.setState({ date: new Date() });
  };

  componentWillUnmount() {
    clearInterval(this.clocktimer);
  }

  render() {
    const { date, locale } = this.state;

    return (
      <div>
        <h6 className="clock">
          <span className="text">{date.toLocaleTimeString(locale)}</span>
        </h6>
      </div>
    );
  }
}
